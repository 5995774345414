const Check = (props) => {
	return (
	  <svg
		width={12}
		height={9}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	  >
		<path
		  d="M12 1.718L4.8 9 0 4.146l1.698-1.717S3.42 4.172 4.8 5.567L10.303 0 12 1.718z"
		  fill="#fff"
		/>
	  </svg>
	);
  }
  
  export default Check