import { useContext, useEffect, useState } from "react"
import { useFormWithValidation } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts'
import styles from './styles.module.css'
import { Button, Container, Form, Input, Main, CKEditor, Alert, Title, TextArea } from "../../components"
import api from '../../api'

const CreateQuestion = () => {
	const { values, handleChange, setIsValid } = useFormWithValidation()
	const [loading, setLoading] = useState(false)
	const [answer, setAnswer] = useState('')
	const [title, setTitle] = useState('')
	const [showAlert, setShowAlert] = useState(false)

	const authContext = useContext(AuthContext)
	const navigate = useNavigate()
	const create = (e) => {
		e.preventDefault()
		if (answer.length > 10000) {
			setTitle('Недопустимое количество сиволов для ответа')
			setShowAlert(true)
		} else api.createQuestion({...values, answer})
			.then(data => navigate(`/question/${data.result}`))
			.catch(err => console.error('Login error', err.toString()))
	}
	const editorChange = (e) => {
		setAnswer(e)
		setIsValid(true)
	}
	useEffect(_ => {
		const user = localStorage.user && JSON.parse(localStorage.user)
		if (!authContext) return navigate('/signin')
		if (!(user?.role.includes('root') || user?.role.includes('sup') || user?.role.includes('supExp'))) return navigate('/')
		else setLoading(true)
	}, [])
	if (loading) return (
		<Main>
			<Alert
				title={title}
				isOpen={showAlert}
				onRequestClose={() => setShowAlert(false)}
				clickHandlerOn={() => setShowAlert(false)}
				on="Ok"
			/>
			<Container>
				<Title title="Новый вопрос" />
				<Form
					className={styles.form}
					onSubmit={create}
				>
					<Input
						required
						label='Вопрос'
						name='value'
						value=''
						inputClassName={styles.inputField}
						inputLabelText={styles.inputLabelText}
						labelClassName={styles.labelClassName}
						onChange={handleChange}
					/>
					<CKEditor
						value={answer}
						label='Ответ'
						onChange={editorChange}
						labelClassName={styles.labelClassName}
					/>
					<TextArea 
						onChange={handleChange}
						rows='3'
						placeholder='Ключевые слова'
						label='Ключевые слова'
						name='tag'
						inputClassName={styles.inputField}
						inputLabelText={styles.inputLabelText}
						labelClassName={styles.labelClassName}
					/>
					<Button
						modifier='style_dark-blue'
						type='submit'
						className={styles.button}>
						Создать
					</Button>
				</Form>
			</Container>
		</Main>
	)
}

export default CreateQuestion