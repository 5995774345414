import { useContext } from 'react'

import styles from './styles.module.css'
import { LinkComponent, Text } from '../index.js'
import { AuthContext } from '../../contexts'

const logout = require('../../images/logout.png')

const AccountMenu = ({ onSignOut, name }) => {
	const authContext = useContext(AuthContext)
	if (!authContext) {
		return (
			<div className={styles.menu}>
				<LinkComponent
					className={styles.menuLink}
					href='/signin'
					title='Войти' />
			</div>
		)
	}
	return (
		<a
			className={styles.menuLink}
			onClick={onSignOut}
			title='Выйти'
			>
			<div className={styles.logout}>
				<Text className={styles.name}>{name}</Text>
				<img src={logout} className={styles.img} alt='logout' />
			</div>
		</a>
	)
}

export default AccountMenu
