import currentUrl from '../configs/init'

class Api {
	constructor(url, headers) {
		this._url = url
		this._headers = headers
	}

	checkResponse(res) {
		return new Promise((resolve, reject) => {
			if (res.status === 204) return resolve(res)
			const func = res.status < 400 ? resolve : reject
			res.json().then(data => func(data))
		})
	}

	signin({ login, password }) {
		return fetch(
			`${this._url}api/auth/login`,
			{
				method: 'POST',
				headers: this._headers,
				body: JSON.stringify({
					login, password
				})
			}
		).then(this.checkResponse)
	}

	getQuestions({ query }) {
		const token = localStorage.getItem('token')
		const authorization = token ? { 'authorization': `Token ${token}` } : {}
		query = query ? '?value=' + query : ''
		return fetch(
			`${this._url}api/support/question${query}`,
			{
				method: 'GET',
				headers: {
					...this._headers,
					...authorization
				}
			}
		).then(this.checkResponse)
	}

	getSingleQuestion({ _id }) {
		const token = localStorage.getItem('token')
		const authorization = token ? { 'authorization': `Token ${token}` } : {}
		return fetch(
			`${this._url}api/support/question/${_id}`,
			{
				method: 'GET',
				headers: {
					...this._headers,
					...authorization
				}
			}
		).then(this.checkResponse)
	}

	createQuestion({ value, answer, tag }) {
		const token = localStorage.getItem('token')
		return fetch(
			`${this._url}api/support/question`,
			{
				method: 'POST',
				headers: {
					...this._headers,
					'authorization': `Token ${token}`
				},
				body: JSON.stringify({
					value,
					answer,
					tag
				})
			}
		).then(this.checkResponse)
	}

	editQuestion({ _id, value, answer, tag }) {
		const token = localStorage.getItem('token')
		return fetch(
			`${this._url}api/support/question?id=${_id}`,
			{
				method: 'PATCH',
				headers: {
					...this._headers,
					'authorization': `Token ${token}`
				},
				body: JSON.stringify({
					value,
					answer,
					tag
				})
			}
		).then(this.checkResponse)
	}

	removeQuestion({ _id }) {
		const token = localStorage.getItem('token')
		return fetch(
			`${this._url}api/support/question?id=${_id}`,
			{
				method: 'DELETE',
				headers: {
					...this._headers,
					'authorization': `Token ${token}`
				}
			}
		).then(this.checkResponse)
	}
	
	getProblems() {
		const token = localStorage.getItem('token')
		const authorization = token ? { 'authorization': `Token ${token}` } : {}
		return fetch(
			`${this._url}api/support/problem`,
			{
				method: 'GET',
				headers: {
					...this._headers,
					...authorization
				}
			}
		).then(this.checkResponse)
	}

	getSingleProblem({ _id }) {
		const token = localStorage.getItem('token')
		const authorization = token ? { 'authorization': `Token ${token}` } : {}
		return fetch(
			`${this._url}api/support/problem/${_id}`,
			{
				method: 'GET',
				headers: {
					...this._headers,
					...authorization
				}
			}
		).then(this.checkResponse)
	}

	createProblem({ value, text, contact }) {
		const token = localStorage.getItem('token')
		return fetch(
			`${this._url}api/support/problem`,
			{
				method: 'POST',
				headers: {
					...this._headers,
					'authorization': `Token ${token}`
				},
				body: JSON.stringify({
					value,
					text,
					contact
				})
			}
		).then(this.checkResponse)
	}

	editProblem(values) {
		const token = localStorage.getItem('token')
		return fetch(
			`${this._url}api/support/problem?id=${values._id}`,
			{
				method: 'PATCH',
				headers: {
					...this._headers,
					'authorization': `Token ${token}`
				},
				body: JSON.stringify({
					...values
				})
			}
		).then(this.checkResponse)
	}

	getUser() {
		const token = localStorage.getItem('token')
		const authorization = token ? { 'authorization': `Token ${token}` } : {}
		return fetch(
			`${this._url}api/support/user`,
			{
				method: 'GET',
				headers: {
					...this._headers,
					...authorization
				}
			}
		).then(this.checkResponse)
	}
}

export default new Api(currentUrl || 'http://127.0.0.1:3050', { 'content-type': 'application/json' })