import { useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { AuthContext, UserContext } from '../../contexts'
import api from "../../api"
import { Container, Problem, ProblemList, Title } from "../../components"
import styles from './styles.module.css'
import { cssClear } from "../../utils/fn"

const Problems = () => {
	cssClear()
	const [problems, setProblems] = useState([])
	const navigate = useNavigate()
	const authContext = useContext(AuthContext)
	const userContext = useContext(UserContext)
	useEffect(_ => {
		const user = localStorage.user && JSON.parse(localStorage.user)
		if (!authContext) return navigate('/signin')
		if (!(user?.role.includes('root') || user?.role.includes('sup') || user?.role.includes('supExp'))) return navigate('/')
		api.getProblems()
			.then(data => setProblems(data.result))
			.catch(err => console.error('Login error', err.toString()))
	}, [])
	const isExpert = userContext?.role.includes('supExp') || userContext?.role.includes('sup') || userContext?.role.includes('root')
	return (
		<Container className={styles.container}>
			<Title title={ isExpert ? 'Все проблемы' : 'Мои проблемы'} />
			<ProblemList content={problems} />
		</Container>
	)
}

export default Problems

