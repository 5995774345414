import Modal from 'react-modal'
import { Button, Title } from '../index.js'
import styles from './styles.module.css'

const Alert = ({
	title,
	isOpen,
	onRequestClose,
	clickHandlerOn,
	on
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			ariaHideApp={false}
			className={styles.confirm}>
			<Title
				title={title}
				className={styles.title}
			/>
			<Button
				className={styles.btn}
				modifier='style_dark-blue'
				clickHandler={clickHandlerOn}>
				{on}
			</Button>
		</Modal>
	)
}

export default Alert