import Modal from 'react-modal'
import { Button, Title } from '../index.js'
import styles from './styles.module.css'

const Confirm = ({
	title,
	isOpen,
	onRequestClose,
	clickHandlerOn,
	clickHandlerOff,
	on,
	off
}) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			ariaHideApp={false}
			className={styles.confirm}
		>
			<Title 
				title={title} 
				className={styles.title}
			/>
			<Button
				className={styles.btn}
				modifier='style_dark-blue'
				clickHandler={clickHandlerOn}>
				{on}
			</Button>
			<Button
				className={styles.btn}
				modifier='style_dark-blue'
				clickHandler={clickHandlerOff}>
				{off}
			</Button>
		</Modal>
	)
}

export default Confirm