import React, { useState, useEffect } from 'react'
import './App.css';
import styles from './styles.module.css'
import api from './api'
import { Header } from './components'
import { Route, Routes } from 'react-router-dom';
import { SignIn, Main, SingleQuestion, EditQuestion, CreateQuestion, CreateProblem, Problem, SingleProblem, PageNotFound, Find } from './pages';
import { AuthContext, UserContext } from './contexts';


function App() {
  const [loggedIn, setLoggedIn] = useState(null)
  const [query, setQuery] = useState('')
  const [user, setUser] = useState(null)

  const authorization = ({ login, password }) => {
    api.signin({ login, password })
      .then(res => {
        localStorage.setItem('token', res.accessToken)
        localStorage.setItem('refresh', res.refreshToken)
        localStorage.setItem('user', JSON.stringify(res.user))
        setLoggedIn(true)
        return api.getUser()
      })
      .then(res => {
        localStorage.setItem('user', JSON.stringify({
          ...JSON.parse(localStorage.user),
          name: res.result?.value
        }))
        setUser(JSON.parse(localStorage.user))
      })
      .catch(err => {
        console.error('Login error', err.toString());
        localStorage.clear('token');
        setLoggedIn(false)
      })
  }

  useEffect(_ => {
    if (localStorage.token && localStorage.user) {
      setUser(JSON.parse(localStorage.user))
      setLoggedIn(true)
    } else onSignOut()
  }, [])

  const onSignOut = () => {
    localStorage.clear();
    setLoggedIn(false)
  }
  if (loggedIn === null) {
    return <div className={styles.loading}>Loading!!!</div>
  }
  return (
    <AuthContext.Provider value={loggedIn}>
      <UserContext.Provider value={user}>
        <Header loggedIn={loggedIn} onSignOut={onSignOut} name={user?.name} />
        <Routes>
          <Route path="/" element={<Main setQuery={setQuery} />} />
          <Route path="/question" element={<Find query={query} setQuery={setQuery} />} />
          <Route path="/signin" element={<SignIn onSignIn={authorization} />} />
          <Route path="/question/create" element={<CreateQuestion />} />
          <Route path="/question/:id" element={<SingleQuestion setQuery={setQuery} query={query} />} />
          <Route path="/question/:id/edit" element={<EditQuestion />} />
          <Route path="/problem/create" element={<CreateProblem />} />
          <Route path="/problem" element={<Problem />} />
          <Route path="/problem/:id" element={<SingleProblem />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
