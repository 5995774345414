import { useContext, useEffect, useState } from "react"
import { Button, CKEditor, Container, Form, Input, Main, TextArea, Title } from "../../components"
import { useFormWithValidation } from '../../utils'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from "../../contexts"
import api from '../../api'
import { cssClear } from "../../utils/fn"

const CreateProblem = () => {
	cssClear()
	const [text, setText] = useState('')
	const { values, handleChange, setIsValid } = useFormWithValidation()
	const [loading, setLoading] = useState(false)
	const authContext = useContext(AuthContext)
	const navigate = useNavigate()

	const create = (e) => {
		e.preventDefault()		
		api.createProblem({ ...values, text })
			.then(data => navigate(`/problem/${data.result}`))
			.catch(err => console.error('Login error', err.toString()))
	}

	useEffect(_ => {
		const user = localStorage.user && JSON.parse(localStorage.user)
		if (!authContext) return navigate('/signin')
		if (!(user?.role.includes('root') || user?.role.includes('sup') || user?.role.includes('supExp'))) return navigate('/')
		else setLoading(true)
	}, [])

	const editorChange = (e) => {
		setText(e)
		setIsValid(true)
	}
	if (loading) return (
		<Main>
			<Container>
				<Title title="Новая проблема" />
				<Form
					className={styles.form}
					onSubmit={create}
				>
					<Input
						required
						label='Суть'
						name='value'
						inputClassName={styles.inputField}
						inputLabelText={styles.inputLabelText}
						labelClassName={styles.labelClassName}
						onChange={handleChange}
					/>
					<CKEditor
						value={text}
						label='Описание'
						onChange={editorChange}
						labelClassName={styles.labelClassName}
					/>
					<TextArea
						required
						rows="3"
						label='Контакты клиента'
						name='contact'
						textareaClassName={styles.textareaClassName}
						labelClassName={styles.labelClassName}
						onChange={handleChange}
					/>
					<Button
						modifier='style_dark-blue'
						type='submit'
						className={styles.button}>
						Создать
					</Button>
				</Form>
			</Container>
		</Main>
	)
}

export default CreateProblem