import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../contexts'
import { useParams, useResolvedPath, useNavigate } from 'react-router-dom'
import { Container, Main, Search, Title, LinkComponent, Button, Confirm, Html, TextArea } from '../../components'
import styles from './styles.module.css'
import api from '../../api'
import { cssClear } from "../../utils/fn"

const SingleQuestion = ({ setQuery, query }) => {
	cssClear()
	const [question, setQuestion] = useState({})
	const _id = useParams().id
	const url = useResolvedPath("").pathname
	const authContext = useContext(AuthContext)
	const navigate = useNavigate()
	const [showConfirm, setShowConfirm] = useState(false)

	useEffect(_ => {
		api.getSingleQuestion({ _id })
			.then(data => {
				if (!data.result) return navigate('/page-not-found')
				setQuestion(data.result)
			})
			.catch(err => {
				console.error('Login error', err.toString())
				return navigate('/page-not-found')
			})
	}, [_id])

	const deleteQuestion = () => {
		api.removeQuestion({ _id })
			.then(_ => navigate('/'))
			.catch(err => console.error('Login error', err.toString()))
	}

	return (
		<Main>
			<Confirm
				title="Вы уверены?"
				isOpen={showConfirm}
				onRequestClose={() => setShowConfirm(false)}
				clickHandlerOff={() => setShowConfirm(false)}
				clickHandlerOn={deleteQuestion}
				on="Да"
				off="Нет"
			/>
			<Container className={styles.container}>
				<Search setQuery={setQuery} query={query} />
				<Title title={question?.value} className={styles.title} />
				<Html value={question?.answer} />
				{authContext && question?.tag && <TextArea
					rows='3'
					label='Ключевые слова'
					value={question?.tag}
					disabled={true}
					labelClassName={styles.labelClassName}
					textareaClassName={styles.textareaField}
				/>}
				<Container className={styles.btn}>
					<Button
						modifier='style_dark-blue'
						className={styles.cancel}
						clickHandler={() => navigate(-1, { replace: true })}>
						Вернуться
					</Button>
					{authContext &&
						<LinkComponent
							title='Редактировать'
							href={`${url}/edit`}
							className={styles.edit}
						/>
					}
					{authContext && <Button
						modifier='style_dark-blue'
						className={styles.delete}
						clickHandler={() => setShowConfirm(true)}
					>
						Удалить
					</Button>}
				</Container>
			</Container>
		</Main>
	)
}

export default SingleQuestion