import styles from './styles.module.css'
import { LinkComponent } from '../index.js'


const Question = ({
	_id,
	value
}) => {
	return (
		<div className={styles.question}>
			<LinkComponent
				className={styles.question__title}
				href={`/question/${_id}`}
				title={value}
			/>
		</div>
	)
}

export default Question