import styles from './styles.module.css'
import ProblemRow from './problem-list-row'
import ProblemHead from './problem-list-head'


const heads = ['#', 'Проблема', 'Создана', 'Автор', 'Решена', 'Сотрудник']

const ProblemList = ({ content }) => {
	return (
		<div className={styles.problemList}>
			<table className={styles.table}>
				<thead className={styles.thead}>
					<ProblemHead heads={heads}/>
				</thead>
				<tbody className={styles.tbody}>
					{content.map((item, index) => (
						<ProblemRow item={item} index={index} key={item._id}/>
					)
					)}
				</tbody>
			</table>
		</div>
	)
}

export default ProblemList