import styles from './styles.module.css'
import cn from 'classnames'

const Text = ({ children, className }) => {
	return (
		<div className={cn(styles.text, className)}>
			{children}
		</div>
	)
}

export default Text