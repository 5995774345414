import { AccountMenu, Container, LinkComponent, Logo, Nav } from '../index.js'
import styles from "./styles.module.css"

const Header = ({ loggedIn, onSignOut, name }) => {
	return (
		<Container>
			<header className={styles.header}>
				<div className={styles.headerContent}>
					<LinkComponent href="/" title={<Logo />} />
					<Nav loggedIn={loggedIn} />
					<AccountMenu onSignOut={onSignOut} name={name} />
				</div>
			</header>
		</Container>
	)
}

export default Header