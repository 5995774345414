import { Container, Main, Title } from '../../components'
import styles from './styles.module.css'
import { cssClear } from "../../utils/fn"

const PnF = () => {
	cssClear()
	return (
		<Main>
			<Container>
				<Title title='404' />
				<Title title='Page not found' />
			</Container>
		</Main>
	)
}

export default PnF