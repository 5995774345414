import styles from './styles.module.css'
import cn from 'classnames'

const Button = ({
	children,
	modifier = 'style_light-blue',
	href,
	clickHandler,
	className,
	disabled,
	type = 'button'
}) => {
	const classNames = cn(styles.button, className, {
		[styles[`button_${modifier}`]]: modifier,
		[styles.button_disabled]: disabled
	})
	if (href) {
		return (
			<a className={classNames} href={href}>
				{children}
			</a>
		)
	}
	return (
		<button
			className={classNames}
			disabled={disabled}
			type={type}
			onClick={_ => clickHandler && clickHandler()} >
				{children}
		</button>
	)
}

export default Button