import { useState, useContext, useEffect } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { useFormWithValidation } from '../../utils'
import { Container, Form, Main, Input, Button, CKEditor, Alert, TextArea } from "../../components"
import { AuthContext } from '../../contexts'
import styles from './styles.module.css'
import api from "../../api"
import { cssClear } from "../../utils/fn"

const EditQuestion = () => {
	cssClear()
	const { values, handleChange, setIsValid } = useFormWithValidation()
	const [answer, setAnswer] = useState(null)
	const [title, setTitle] = useState('')
	const [showAlert, setShowAlert] = useState(false)
	const navigate = useNavigate()
	const authContext = useContext(AuthContext)
	const _id = useParams().id
	const [question, setQuestion] = useState({})
	useEffect(_ => {
		if (!authContext) return navigate('/signin')
		api.getSingleQuestion({ _id })
			.then(data => {
				setQuestion(data.result)
				setAnswer(data.result?.answer)
			})
			.catch(err => {
				console.error('Login error', err.toString())
				return navigate('/page-not-found')
			})
	}, [_id])

	const edit = (e) => {
		e.preventDefault()
		if (answer.length > 10000) {
			setTitle('Недопустимое количество сиволов для ответа')
			setShowAlert(true)
		} else api.editQuestion({
			_id,
			value: values.value ?? question.value,
			answer: values.answer ?? answer,
			tag: values.tag ?? question.tag
		})
			.then(_ => navigate(-1, {replace: true}))
			.catch(err => console.error('Login error', err.toString()))
	}
	const editorChange = (e) => {
		setAnswer(e)
		setIsValid(true)
	}

	return (
		<Main>
			<Alert
				title={title}
				isOpen={showAlert}
				onRequestClose={() => setShowAlert(false)}
				clickHandlerOn={() => setShowAlert(false)}
				on="Ok"
			/>
			<Container className={styles.container}>
				<Form
					className={styles.form}
					onSubmit={edit}
				>
					<Input
						required
						label='Вопрос'
						name='value'
						value={question?.value}
						inputClassName={styles.inputField}
						inputLabelText={styles.inputLabelText}
						labelClassName={styles.labelClassName}
						onChange={handleChange}
					/>
					<CKEditor
						value={answer}
						label="Ответ"
						onChange={editorChange}
						labelClassName={styles.labelClassName}
					/>
					<TextArea
						rows='3'
						label='Ключевые слова'
						value={question?.tag}
						name='tag'
						labelClassName={styles.labelClassName}
						onChange={handleChange}
					/>
					<Button
						modifier='style_dark-blue'
						type='submit'
						className={styles.button}>
						Сохранить
					</Button>
					<Button
						modifier='style_dark-blue'
						className={styles.cancel}
						clickHandler={() => navigate(-1, {replace: true})}>
						Отмена
					</Button>
				</Form>
			</Container>
		</Main>
	)
}

export default EditQuestion