import { Container, Input, Main, Title, Form, Button } from '../../components'
import styles from './styles.module.css'
import { useFormWithValidation } from '../../utils'
import { AuthContext } from '../../contexts'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { cssClear } from "../../utils/fn"

const SignIn = ({ onSignIn }) => {
	cssClear()
	const { values, handleChange, isValid } = useFormWithValidation()
	const authContext = useContext(AuthContext)
	const navigate = useNavigate()
	useEffect(_ => {
		if (authContext) navigate('/')
	})
	return (
		<Main>
			<Container>
				<Title title='Войти на сайт' />
				<Form
					className={styles.form}
					onSubmit={e => {
						e.preventDefault()
						onSignIn(values)
					}}>
					<Input
						required
						label='Логин'
						name='login'
						onChange={handleChange} />
					<Input
						required
						label='Пароль'
						type='password'
						name='password'
						onChange={handleChange} />
					<Button
						modifier='style_dark-blue'
						disabled={!isValid}
						type='submit'
						className={styles.button}>
						Войти
					</Button>
				</Form>
			</Container>
		</Main>
	)
}

export default SignIn