const data = {
	url: 'https://market-api.flet.su/', //Production
	// url: 'https://a-market-api.flet.su/', //Production test
	// url: 'http://192.168.1.99:3050/', //market.api
	// url: 'http://192.168.1.101:3050/', //Рома
	// url: 'http://192.168.1.209:3050/', //Ромаs
	// url: 'http://91.202.204.194:3050/', //Рома
	// url: 'http://192.168.1.104:3050/', //Юра
	// url: 'http://192.168.1.160:3050/', //Аскар
	// url: 'http://192.168.1.103:3050/', //Саша
	// url: 'http://192.168.1.110:3050/', //Сергей
}

export default data.url