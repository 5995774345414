import { Button, Form, Input } from "../index.js"
import { useFormWithValidation } from '../../utils'
import { useNavigate } from 'react-router-dom'
import styles from "./styles.module.css"

const Search = ({ setQuery, query }) => {
	const placeholder = "С чего начать работу с Флет"
	const navigate = useNavigate()
	const { values, handleChange } = useFormWithValidation()
	const change = (e) => {
		e.preventDefault()
		setQuery(values.query ?? query ?? placeholder)
		navigate('/question')
	}
	return (
		<Form
			className={styles.form}
			onSubmit={change}
		>
			<Input
				inputClassName={styles.inputField}
				placeholder={placeholder}
				className={styles.input}
				name="query"
				onChange={handleChange}
				value={query}
			/>
			<Button
				className={styles.btn}
				type="submit"
				modifier={'style_dark-blue'}
			>
				Найти
			</Button>
		</Form>
	)
}

export default Search