import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { useFormWithValidation } from '../../utils'
import { AuthContext } from '../../contexts'
import api from "../../api"
import { Alert, Button, Checkbox, Container, Form, Input, Main, TextArea, CKEditor, PageNotFound } from "../../components"
import styles from './styles.module.css'
import { cssClear } from "../../utils/fn"


const SingleProblem = () => {
	cssClear()
	const [problem, setProblem] = useState({})
	const [title, setTitle] = useState('')
	const [solution, setSolution] = useState(null)
	const [value, setValue] = useState(false)
	const [text, setText] = useState(null)
	const [showAlert, setShowAlert] = useState(false)

	const _id = useParams().id
	const authContext = useContext(AuthContext)
	const navigate = useNavigate()
	const { values, handleChange, setIsValid } = useFormWithValidation()

	useEffect(_ => {
		const user = localStorage.user && JSON.parse(localStorage.user)
		if (!authContext) return navigate('/signin')
		if (!(user?.role.includes('root') || user?.role.includes('exp') || user?.role.includes('supExp'))) return navigate('/')
		api.getSingleProblem({ _id })
			.then(data => {
				if (!data.result) navigate('/page-not-found')
				setProblem(data.result)
				setText(data.result?.text ?? '')
				setSolution(data.result?.solution ?? '')
			})
			.catch(err => {
				console.error('Login error', err.toString())
				return navigate('/page-not-found')
			})
	}, [authContext, _id])

	const checkText = (text) => {
		return !text || !text
			.split('&nbsp;').join('')
			.split('<p>').join('')
			.split('<br>').join('')
			.split('</p>').join('')
			.trim()
			.length > 0
	}

	useEffect(_ => {
		if (checkText(solution)) setValue(false)
	}, [])

	const closeChange = () => {
		if (checkText(solution)) {
			setTitle('Нельзя закрыть проблему без решения')
			setShowAlert(true)
		} else setValue(!value)
	}

	const textChange = (e) => {
		setText(e)
		setIsValid(true)
	}
	const solutionChange = (e) => {
		setSolution(e)
		setIsValid(true)
	}
	const editProblem = (e) => {
		e.preventDefault()
		if (value) values.closed = true
		values._id = _id
		if (!solution) delete values.closed
		api.editProblem({ ...values, text, solution })
			.then(_ => navigate('/problem'))
			.catch(err => console.error('Login error', err.toString()))
	}
	return (
		<Main>
			<Alert
				title={title}
				isOpen={showAlert}
				onRequestClose={() => setShowAlert(false)}
				clickHandlerOn={() => setShowAlert(false)}
				on="Ok"
			/>
			<Container>
				<Form
					className={styles.form}
					onSubmit={editProblem}
				>
					<Input
						required
						value={problem.value}
						label='Суть'
						name='value'
						inputClassName={styles.inputField}
						inputLabelText={styles.inputLabelText}
						labelClassName={styles.labelClassName}
						onChange={handleChange}
					/>
					<CKEditor
						value={text}
						label='Описание'
						onChange={e => textChange(e)}
						labelClassName={styles.labelClassName}
					/>
					<TextArea
						rows="3"
						label='Контакты клиента'
						name='contact'
						value={problem.contact}
						textareaClassName={styles.textareaClassName}
						labelClassName={styles.labelClassName}
						onChange={e => {
							setProblem({ ...problem, contact: e.target.value })
							values.contact = e.target.value
						}}
					/>
					<CKEditor
						value={solution}
						label='Решение'
						onChange={e => solutionChange(e)}
						labelClassName={styles.labelClassName}
					/>
					<Checkbox
						color='red'
						name='Закрыта'
						onChange={closeChange}
						value={value}
						className={styles.checkbox}
					/>
					<Button
						modifier='style_dark-blue'
						type='submit'
						className={styles.button}>
						Сохранить
					</Button>
				</Form>
			</Container>
		</Main>
	)
}

export default SingleProblem