import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'

const Row = ({ item, index }) => {
	const navigate = useNavigate()

	const time = (date) => {
		if (!date) return null
		date = new Date(date)
		return date.toLocaleDateString() + ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
	}

	const text = (value) => {
		if (value.length < 55) return value
		return value.slice(0, 55).trim() + '...'
	}
	
	return (
		<tr onClick={_ => navigate(`/problem/${item._id}`)}>
			<td className={styles.number}>{index + 1}</td>
			<td>{text(item.value)}</td>
			<td className={styles.date} title={time(item.start.date)}>{new Date(item.start.date).toLocaleDateString()}</td>
			<td className={styles.name}>{item.start.employee}</td>
			<td className={styles.date} title={time(item.end?.date)}>{item.end?.date ? new Date(item.end?.date).toLocaleDateString() : ''}</td>
			<td className={styles.name}>{item.end?.employee}</td>
		</tr>
	)
}

export default Row