const menu = [
	{
		title: "Главная",
		href: "/",
		auth: false,
	},
	{
		title: "Создать вопрос",
		href: "/question/create",
		auth: true,
		expert: true,
		ttl: "Задать вопрос тех.поддержке системы Флет"
	},
	{
		title: "Создать проблему",
		href: "/problem/create",
		auth: true,
		support: true,
		ttl: "Задать вопрос разработчикам системы Флет"
	},
	{
		title: "Мои проблемы",
		href: "/problem",
		auth: true,
		support: true,
		not_expert: true,
		ttl: "Посмотреть ответы на заданные вопросы разработчикам системы Флет",
	},
	{
		title: "Все проблемы",
		href: "/problem",
		auth: true,
		expert: true,
		ttl: "Посмотреть список вопросов, заданных разработчикам системы Флет"
	},	
	{
		title: "Галерея",
		href: "https://cloud-img.flet.su/gallery",
		auth: true,
		expert: true,
		target: "_blank",
		ttl: "Картинки для использования в системе Флет"
	},
	{
		title: "Иконки",
		href: "https://cloud-img.flet.su/icons",
		auth: false,
		target: "_blank",
		ttl: "Готовые иконки, которые предоставляет система Флет"
	}
]

export default menu