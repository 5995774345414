import { Link } from 'react-router-dom'
import styles from './styles.module.css'
import cn from 'classnames'

const LinkComponent = ({ href, className, title, target, ttl }) => {
	return (
		<Link className={cn(styles.link, className)} to={href} target={target} title={ttl}>
			{title}
		</Link>
	)
}

export default LinkComponent