import { useEffect, useState } from "react"
import { Container, Question, QuestionList, Search } from "../../components"
import styles from './styles.module.css'
import api from "../../api"
import { cssClear } from "../../utils/fn"

const Find = ({query, setQuery}) => {
	cssClear()
	const [questions, setQuestions] = useState([])
	useEffect(_ => {
		getQuestions({query})
	}, [query])

	const getQuestions = () => {
		api.getQuestions({query})
			.then(res => {
				setQuestions(res.result)
			})
			.catch(err => console.error('Login error', err.toString()))
	}
	return (
		<Container className={styles.container}>
			<Search setQuery={setQuery} query={query}/>
			<QuestionList>
				{questions.map(item => <Question
					{...item}
					key={item._id}
				/>)}
			</QuestionList>
		</Container>
	)
}

export default Find