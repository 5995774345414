import { useEffect, useState } from "react"
import cn from 'classnames'
import styles from './styles.module.css'

const TextArea = ({
	onChange,
	rows = "8",
	placeholder,
	label,
	disabled,
	textareaClassName,
	labelClassName,
	value,
	name,
	required,
	className
}) => {
	const [inputValue, setInputValue] = useState(value)
	const handleValueChange = (e) => {
		const value = e.target.value
		setInputValue(value)
		onChange(e)
	}
	useEffect(_ => {
		if (value !== inputValue) {
			setInputValue(value)
		}
	}, [value])

	return (
		<div className={cn(styles.textarea, className)}>
			<label className={cn(styles.textareaLabelm, labelClassName)}>
				<div className={styles.textareaLabelText}>
					{label}
				</div>
				<textarea
					rows={rows}
					value={inputValue}
					name={name}
					className={cn(styles.textareaField, textareaClassName)}
					onChange={e => handleValueChange(e)}
					disabled={disabled}
					placeholder={placeholder}
					required={required}

				/>
			</label>
		</div>
	)
}

export default TextArea