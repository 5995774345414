import styles from './styles.module.css'
import cn from 'classnames'
import { LinkComponent } from '../index.js'
import navigation from '../../configs/navigation'
import { UserContext } from '../../contexts'
import { useContext } from 'react'

const Nav = ({ loggedIn }) => {
	const user = useContext(UserContext)
	const isExpert = user?.role.includes('supExp') || user?.role.includes('root')
	const isSupport = user?.role.includes('sup') || user?.role.includes('root')
	return (
		<nav className={styles.nav}>
			<div className={styles.nav__container}>
				<ul className={styles.nav__item}>
					{navigation.map(item => {
						if (!loggedIn && item.auth) return null
						if (isExpert && (item.not_expert && !item.expert)) return null
						if (!isExpert && (item.expert && !item.not_expert)) return null
						if (!isSupport && item.support && !isExpert) return null
						return (
							<li className={styles.nav__item} key={item.href}>
								<LinkComponent
									title={item.title}
									href={item.href}
									exact
									className={styles.nav__link}
									target={item.target}
									ttl={item.ttl}
								/>
							</li>
						)
					})}
				</ul>
			</div>
		</nav>
	)
}

export default Nav