import { CKEditor } from 'ckeditor4-react'
import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './styles.module.css'



const Editor = ({
	value,
	onChange,
	label,
	labelClassName
}) => {
	const [inputValue, setInputValue] = useState(value)

	const config = {
		extraPlugins: 'justify',
		toolbar: [
			{ name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
			{ name: 'editing', items: [ 'Scayt' ] },
			{ name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
			{ name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'SpecialChar' ] },
			{ name: 'tools', items: [ 'Maximize' ] },
			{ name: 'document', items: [ 'Source' ] },
			'/',
			{ name: 'basicstyles', items: [ 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat' ] },
			{ name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'JustifyLeft', 'JustifyRight', 'JustifyCenter', 'JustifyBlock' ] },
			{ name: 'styles', items: [ 'Styles', 'Format' ] },
			{ name: 'about', items: [ 'About' ] },
		],
		// uiColor: '#58b80c',
		removeButtons: 'Subscript,Superscript,Anchor,About',
		format_tags: 'p;h1;h2;h3;pre',
		removeDialogTabs: 'image:advanced;link:advanced',
		height: "500"
	}

	useEffect(_ => {
		if (value !== inputValue) {
			setInputValue(value)
		}
	}, [value])

	const handleValueChange = (e) => {
		const data = e.getData()
		setInputValue(data)
		onChange(data)
	}

	return inputValue === null ? null : (
		<div className={styles.editor}>
			{label && <div className={cn(styles.inputLabelText, labelClassName)}> {label} </div>}
			<CKEditor
				config={config}
				initData={inputValue}
				onChange={({ editor }) => handleValueChange(editor)}
			/>
		</div>
	)
}

export default Editor

