import Modal from 'react-modal'
import { Icons } from '../index'

import { useState } from 'react'

import styles from './styles.module.css'


const Html = ({ value }) => {
	const [showImg, setShowImg] = useState(false)
	const [img, setImg] = useState(null)
	return (
		<div>
			{showImg && <div className={styles.shadow}></div>}
			<Modal
				isOpen={showImg}
				onRequestClose={() => setShowImg(false)}
				clickHandlerOn={() => setShowImg(false)}
				className={styles.modal}
			>
				<div className={styles.close} onClick={() => setShowImg(false)}><Icons.Close/></div>
				{img}
			</Modal>
			<div
				className={styles.html}
				dangerouslySetInnerHTML={{ __html: value }}
				onClick={e => open(e, showImg, setShowImg, img, setImg)}
			/>
			
		</div>
	)

}

export default Html

function open(e, show, setShow, img, setImg) {
	if (e.target.localName !== 'img') {
		setShow(false)
		return
	}
	setShow(!show)
	setImg((<img src={e.target.currentSrc} className={styles.imgModal}/>))
}